.button-box {
  max-width: 40px !important;
}

.label-button {
  text-transform: none;
}

.paper-login {
  height: 100%;
  padding: 0 24px;
  .tagline-forgot-password {
    margin: 0 0 30px 0
  }
}

.login_logo {
  width: 200px;
}

.title{
  margin-top: 5px;
}

.MuiDialog-paper{
  border-radius: 16px !important;
}
