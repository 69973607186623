.button-box {
  max-width: 40px !important;
}

.mb-25{
  margin-bottom: 25px !important;
}

.label-button {
  text-transform: none;
}

.paper-login {
  height: 100%;
  padding: 20px 0 20px 0;
  min-width: 350px;
}

.login_logo {
  width: 200px;
}

.c-red{
  color: #DD2C00 !important;
}
