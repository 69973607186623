.login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_wrapper {
  max-width: 450px;
}

.head-title{
  font-weight: 700;
  size:32px
}

.tagline-section{
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tagline-text{
  color: white !important;
text-align: center;
  max-width: 350px;
}
