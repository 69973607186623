.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-full {
  flex: 1;
}

.v-center {
  align-items: center;
}

.h-center {
  justify-content: center;
}

.direction-reverse {
  flex-direction: row-reverse;
}

.h-space-between {
  justify-content: space-between
}

.h-end {
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100%;
}
