.content-loading {
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  border-radius: 2px;
  border: none !important;
  background: linear-gradient(to right, #ddd 2%, #eee 18%, #ddd 33%);
  transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
  &:after,
  &:before {
    width: inherit;
    height: inherit;
    content: '';
    position: absolute;
  }
  &.menu-list{
    margin: 20px;
    &:nth-child(odd) {
      width: 80%;
      height: 1rem;
    }
    &:nth-child(even) {
      width: 60%;
      height: 1rem;
    }
  }
  &.header--logo{
    display: inline-block;
    width: 30px;
    height: 30px;
  }
  &.main-content{
    margin: 10px;
    &:nth-child(1) {
      width: 20%;
      height: 1rem;
    }
    &:nth-child(2) {
      margin-top: 20px;
      width: 70%;
      height: 1rem;
    }
    &:nth-child(3) {
      width: 60%;
      height: 1rem;
    }
  }
}
@keyframes placeholderAnimate {
  0%{ background-position: -650px 0; }
  100%{ background-position: 650px 0; }
}

.list_logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    object-fit: contain;
  }
}

.list_child {
  text-decoration: none;
}

.sidebar_item-wrapper {
  margin-top: 20px;
  flex-grow: 1;
}

.image-profile{
  height: 17px;
  margin: 5px;
}

.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
