// PRIMARY COLOR
$primary: #19B1A1;
$primary-light: #93D6CE;
$primary-lighter: #CAF7F2;
$primary-darkest: #19B1A1;

// ALERT
$alert-secondary: #21BF73;

// WARNING
$rose: #DD2C00;
$rose-light: #FFE4DD;

// VARIANT
$mist: #BDBDBD;
$misty-light: #EFF0F2;
$misty-darkest: #868686;

// Z-INDEX
$navbar-z-index: 10;
$sidebar-z-index: 11;
$modal-bg-z-index: 50;