@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('../font/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black';
  font-style: normal;
  src: local('Montserrat Black'), local('Montserrat-Black'), url('../font/montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black Italic';
  font-style: normal;
  src: local('Montserrat Black Italic'), local('Montserrat-Black-Italic'), url('../font/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('../font/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold Italic';
  font-style: normal;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url('../font/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  font-style: normal;
  src: local('Montserrat Extra Bold'), local('Montserrat-ExtraBold'), url('../font/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Bold Italic';
  font-style: normal;
  src: local('Montserrat Extra Bold Italic'), local('Montserrat-ExtraBoldItalic'), url('../font/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Light';
  font-style: normal;
  src: local('Montserrat Extra Light'), local('Montserrat-ExtraLight'), url('../font/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Light Italic';
  font-style: normal;
  src: local('Montserrat Extra Light Italic'), local('Montserrat-ExtraLightItalic'), url('../font/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Italic';
  font-style: normal;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url('../font/montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  src: local('Montserrat Light'), local('Montserrat-Light'), url('../font/montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light Italic';
  font-style: normal;
  src: local('Montserrat Light Italic'), local('Montserrat-Light-Italic'), url('../font/montserrat/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('../font/montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium Italic';
  font-style: normal;
  src: local('Montserrat Medium Italic'), local('Montserrat-Medium-Italic'), url('../font/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('../font/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Semi Bold';
  font-style: normal;
  src: local('Montserrat Semi Bold'), local('Montserrat-Semi-Bold'), url('../font/montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Semi Bold Italic';
  font-style: normal;
  src: local('Montserrat Semi Bold Italic'), local('Montserrat-Semi-Bold-Italic'), url('../font/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Thin';
  font-style: normal;
  src: local('Montserrat Thin'), local('Montserrat-Thin'), url('../font/montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Thin Italic';
  font-style: normal;
  src: local('Montserrat Thin Italic'), local('Montserrat-Thin-Italic'), url('../font/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}

html, body {
  font-family: 'Montserrat Regular' !important;
  font-size: 14px;
  color: black;
  background-color: #E5E5E5;
}
