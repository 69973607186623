@import 'src/assets/scss/variables';

.navbar {
  height: 80px;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: $navbar-z-index;
  box-shadow: 0 4px 20px 0 #0000000D;


}

.merchant-brand{
  margin-left: 250px;
}
