.title{
  font-family: "Montserrat Bold";
  &--sub{
    font-family: "Montserrat Medium";
    font-size: 12px;
  }
}

.btn-close{
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 10px;
}
